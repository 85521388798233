//获取话题列表
const getTopicListURL = `/gateway/hc-neighbour/topic/getTopicList`;
//获取排行版列表
const getCommentPraiseRankingListURL =
  "/gateway/hc-neighbour/topic/getCommentPraiseRankingList";

//新增话题
const addTopicURL = `/gateway/hc-neighbour/topic/addTopic`;
//更新话题
const updateTopicURL = `/gateway/hc-neighbour/topic/updateTopic`;

//详情
const getTopicInfoByIdURL = `/gateway/hc-neighbour/topic/getTopicInfoById`;

//获取园区列表
const getCommunityListUrl = `/gateway/hc-space/space/community`;
const getBuildListURL = `/gateway/hc-space/space/getSpaceTreeList`;

export {
  getTopicListURL,
  addTopicURL,
  updateTopicURL,
  getCommunityListUrl,
  // getInitInfoURL,
  getCommentPraiseRankingListURL,
  getTopicInfoByIdURL,
  getBuildListURL,
};
