import { mapHelper } from "@/utils/common.js";

const status = [
  {
    value: 1,
    label: "发布",
  },
  {
    value: 3,
    label: "取消",
  },
];

const { map: statusMap, setOps: setStatusOps } = mapHelper.setMap(status);

// 是否置顶，是否可以点赞，是否可以评论
const isTop = [
  {
    value: 0,
    label: "否",
  },
  {
    value: 1,
    label: "是",
  },
];

const { map: isTopMap, setOps: setIsTopOps } = mapHelper.setMap(isTop);

const sortField = [
  // {
  //   value: undefined,
  //   label: "正常",
  // },
  {
    value: "NEW",
    label: "最新",
  },
  {
    value: "HOT",
    label: "最热",
  },
];

const { map: sortFieldMap, setOps: sortFieldOps } = mapHelper.setMap(sortField);
// 用户类别
const userType = [
  {
    value: "10",
    checked: false,
    label: "居民",
  },
  {
    value: "11",
    checked: false,
    label: "企业/商户",
  },
  // {
  //   value: "21",
  //   checked: false,
  //   label: "运营人员",
  // },
];
const { map: userTypeMap, setOps: setUserTypeOps } = mapHelper.setMap(userType);
export {
  statusMap,
  setStatusOps,
  isTopMap,
  setIsTopOps,
  sortFieldMap,
  sortFieldOps,
  userTypeMap,
  setUserTypeOps,
};
