<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-select
          clearable
          :options="isTopOps"
          v-model="searchParam.isTop"
          @change="$refs.list.search()"
          label="是否置顶"
        />
        <v-select
          clearable
          :options="statusOps"
          v-model="searchParam.status"
          @change="$refs.list.search()"
          label="状态"
        />

        <v-select
          clearable
          :options="sortFieldOps"
          v-model="searchParam.sortField"
          @change="$refs.list.search()"
          label="话题排序"
        />

        <v-input label="话题标题" v-model="searchParam.topicTitle" />
        <!-- <v-input label="社区达人姓名" v-model="searchParam.talentUserName" /> -->
        <v-date-picker
          label="创建时间"
          v-model="createTime"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="创建开始时间"
          endPlaceholder="创建结束时间"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          type="text"
          @click="toEdit(scope.row)"
        />
        <v-button
          text="复制"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          type="text"
          @click="toCopy(scope.row)"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import { getTopicListURL } from "./api.js";
import {
  statusMap,
  setStatusOps,
  isTopMap,
  setIsTopOps,
  sortFieldMap,
  sortFieldOps,
} from "./map.js";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";

export default {
  name: "topicList",
  data() {
    return {
      searchParam: {
        communityId: null,
        topicTitle: "",
        topicType: 5,
        // title: null,
        status: undefined,
        // talentType: undefined,
        // talentUserName: "",
        createTimeS: "",
        createTimeE: "",
        sortField: "NEW", // NEW 最新 HOT 最热
      },
      sortFieldOps: sortFieldOps(),
      sortFieldMap,
      isTopOps: setIsTopOps(),
      statusOps: setStatusOps(),
      tableUrl: getTopicListURL,
      headers: [
        // {
        //   prop: "talentUserName",
        //   label: "社区达人姓名",
        // },
        // {
        //   prop: "talentType",
        //   label: "社区达人类型",
        //   formatter: (row, prop) => {
        //     return this.talentTypeMap[row[prop]] || "--";
        //   },
        // },
        {
          prop: "topicPicture",
          label: "话题封面图",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "topicTitle",
          label: "话题标题",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "isTop",
          label: "是否置顶",
          formatter: (row, prop) => {
            return isTopMap[row[prop]] || "--";
          },
        },
        {
          prop: "closePraise",
          label: "关闭点赞",
          formatter: (row, prop) => {
            return isTopMap[row[prop]] || "--";
          },
        },
        {
          prop: "closeComment",
          label: "关闭评论",
          formatter: (row, prop) => {
            return isTopMap[row[prop]] || "--";
          },
        },
        {
          prop: "status",
          label: "状态",
          formatter: (row, prop) => {
            return statusMap[row[prop]] || "--";
          },
        },
      ],
    };
  },
  computed: {
    createTime: {
      get() {
        return !this.searchParam.createTimeS && !this.searchParam.createTimeE
          ? []
          : [this.searchParam.createTimeS, this.searchParam.createTimeE];
      },
      set(val) {
        [this.searchParam.createTimeS, this.searchParam.createTimeE] = val || [
          "",
          "",
        ];
      },
    },
  },
  mounted() {},
  methods: {
    toAdd() {
      this.$router.push({
        name: "bannerForm",
      });
    },
    toEdit(row) {
      this.$router.push({
        name: "bannerForm",
        query: { id: row.id, communityId: row.communityId },
      });
    },
    toCopy(row) {
      this.$router.push({
        name: "bannerForm",
        query: { id: row.id, communityId: row.communityId, isCopy: 1 },
      });
    },
    toSort(row) {
      this.$router.push({
        name: "topicSortList",
        query: {
          id: row.id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
